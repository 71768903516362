<template>
  <b-card>
    <validation-observer ref="roleForm">
      <b-form>
        <b-row class="mt-2">
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Role Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.role_name"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="Role Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="text-uppercase text-bold "></div>
            <div class="table-responsive">
            <table class="table table-striped table-hover ">
              <thead>
                <tr>
                  <th>Module</th>
                  <th>Read</th>
                  <th>Create</th>
                  <th>Update</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(mod, index) in role_modules_map"
                  :key="'roles_table' + index"
                >
                  <td>{{ humanize(mod.module_name) }}</td>
                  <td>
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="mod.read" />
                      <i class="form-icon"></i>
                    </label>
                  </td>
                  <td>
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="mod.create" />
                      <i class="form-icon"></i>
                    </label>
                  </td>
                  <td>
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="mod.update" />
                      <i class="form-icon"></i>
                    </label>
                  </td>
                  <td>
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="mod.delete" />
                      <i class="form-icon"></i>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          </b-col>

          <b-col cols="12" class="text-center">
            <b-button variant="primary" type="submit" @click.prevent="saveRole">
              Save
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
  BTable,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import { BFormSelect } from "bootstrap-vue";
import * as UserServices from "@/apiServices/UsersServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { humanize } from "@/utils/helpers";

export default {
  name: "IngredientForm",
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      item_id: null,
      form: {
        role_id: null,
        role_name: null,
      },
      role_modules_map: [],
      required,
    };
  },
  methods: {
    humanize,
    async saveRole() {
      try {
        const response = await UserServices.saveRole({
          ...this.form,
          modules_map: this.modules_map,
        });
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Saved Succesfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$router.go(-1);
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Cannot Save Role",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.log("Error in saveRole", err);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }
    },
    async getRole() {
      try {
        const response = await UserServices.getSingleRole({
          role_id: this.form.role_id,
        });
        if (response.data.status) {
          this.form = response.data.data;
        }
      } catch (err) {
        console.log("Error in getting role  ", err);
      }
    },
    async getRoleModuleMap() {
      try {
        const response = await UserServices.getRoleModuleMap({
          role_id: this.form.role_id,
        });
        if (response.data.status) {
          this.role_modules_map = response.data.data;
        }
      } catch (err) {
        console.log("Error in getting role module map ", err);
      }
    },
    async saveRole() {
      this.$refs.roleForm.validate().then(async (success) => {
        if (!success) return;
        try {
          const response = await UserServices.saveRole({
            role: this.form,
            role_modules_map: this.role_modules_map,
          });

          if (response.data.status) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Success",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.$router.go(-1);
          }
        } catch (error) {
          console.log("Error in saving role ", error);
        }
      });
    },
  },
  beforeMount() {
    const role_id = this.$route.params.role_id;

    if (role_id && !isNaN(role_id)) {
      this.form.role_id = role_id;
      this.getRole();
    }
    this.getRoleModuleMap();
  },
};
</script>

<style></style>
